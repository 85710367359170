import React from 'react';

import Header from '../partials/Header';
import PricingTables from '../partials/PricingTables';
import PricingTable from '../partials/PricingTable';
import FeaturesTable from '../partials/FeaturesTable';
import TestimonialsCarousel from '../partials/TestimonialsCarousel';
import Faqs from '../partials/Faqs';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';

function Pricing() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <section className="bg-gradient-to-b from-white to-gray-100">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-32 pb-12 md:pt-40">
                    <PricingTable />
                </div>
            </div>
        </section>
        
        {/* <FeaturesTable /> */}
        {/* <TestimonialsCarousel /> */}
        <Faqs />
        <Cta />   

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Pricing;
import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/LayoutDefault";
import Pricing from "../template/pages/Pricing";
import SEO from "../components/SEO";

const PricingPage = (props) => {
    return <>
        <Layout {...props}>
            <SEO title={'Pricing | Retain Rocket'} />
            <Pricing {...props} />
        </Layout>
    </>
}

export default PricingPage;
